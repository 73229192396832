/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchUsers = /* GraphQL */ `
  query SearchUsers($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchUsers(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        username
        email
        status
        enabled
        groups
        userCreateDate
        userLastModifiedDate
        custom__lookup_codes
        custom__domains
        phone
      }
    }
  }
`;
export const getPoliciesByPhone = /* GraphQL */ `
  query GetPoliciesByPhone($phoneNumber: String!) {
    getPoliciesByPhone(phoneNumber: $phoneNumber)
  }
`;
export const getCaptureUrl = /* GraphQL */ `
  query GetCaptureUrl {
    getCaptureUrl
  }
`;
export const getRetrieveUrl = /* GraphQL */ `
  query GetRetrieveUrl($token: String!, $reference: String!) {
    getRetrieveUrl(token: $token, reference: $reference)
  }
`;
export const getBinInfo = /* GraphQL */ `
  query GetBinInfo($bin: String!) {
    getBinInfo(bin: $bin)
  }
`;
export const getTracking = /* GraphQL */ `
  query GetTracking($query: String!) {
    getTracking(query: $query)
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($lookupCode: String!) {
    getContacts(lookupCode: $lookupCode)
  }
`;
export const getUwNotes = /* GraphQL */ `
  query GetUwNotes($uniqPolicy: Int!, $uniqLine: Int!) {
    getUwNotes(uniqPolicy: $uniqPolicy, uniqLine: $uniqLine)
  }
`;
export const getAutoDocumentData = /* GraphQL */ `
  query GetAutoDocumentData($uniqPolicy: Int!, $uniqLine: Int!) {
    getAutoDocumentData(uniqPolicy: $uniqPolicy, uniqLine: $uniqLine)
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($documentId: String!) {
    getDocument(documentId: $documentId)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($username: String!) {
    getUser(username: $username) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__lookup_codes
      custom__domains
      phone
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__lookup_codes
      custom__domains
      phone
    }
  }
`;
export const userEmailExists = /* GraphQL */ `
  query UserEmailExists($email: String) {
    userEmailExists(email: $email)
  }
`;
export const getAvailableGroups = /* GraphQL */ `
  query GetAvailableGroups {
    getAvailableGroups {
      name
      description
      createDate
      lastModifiedDate
      precedence
    }
  }
`;
export const searchGroups = /* GraphQL */ `
  query SearchGroups($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchGroups(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        name
        description
        createDate
        lastModifiedDate
        precedence
      }
    }
  }
`;
export const getDomains = /* GraphQL */ `
  query GetDomains {
    getDomains {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const searchDomains = /* GraphQL */ `
  query SearchDomains($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchDomains(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        name
        description
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const getIcpAdminDashboard = /* GraphQL */ `
  query GetIcpAdminDashboard {
    getIcpAdminDashboard
  }
`;
export const getIcpTeamLeadDashboard = /* GraphQL */ `
  query GetIcpTeamLeadDashboard {
    getIcpTeamLeadDashboard
  }
`;
export const getIcpBrokerDashboard = /* GraphQL */ `
  query GetIcpBrokerDashboard($email: String) {
    getIcpBrokerDashboard(email: $email)
  }
`;
export const getIcpPolicyLog = /* GraphQL */ `
  query GetIcpPolicyLog($email: String) {
    getIcpPolicyLog(email: $email)
  }
`;
export const getScoopTeamData = /* GraphQL */ `
  query GetScoopTeamData {
    getScoopTeamData
  }
`;
export const getTeamDataFile = /* GraphQL */ `
  query GetTeamDataFile($key: String) {
    getTeamDataFile(key: $key)
  }
`;
export const getAnnouncements = /* GraphQL */ `
  query GetAnnouncements {
    getAnnouncements {
      id
      message
      order
      enabled
      createdAt
      updatedAt
      owner
    }
  }
`;
export const searchAnnouncements = /* GraphQL */ `
  query SearchAnnouncements(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchAnnouncements(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        message
        order
        enabled
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const getFaqs = /* GraphQL */ `
  query GetFaqs {
    getFaqs {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const searchFaqs = /* GraphQL */ `
  query SearchFaqs($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchFaqs(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        question
        answer
        category
        order
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const getLibraryLinks = /* GraphQL */ `
  query GetLibraryLinks {
    getLibraryLinks {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const searchLibraryLinks = /* GraphQL */ `
  query SearchLibraryLinks(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchLibraryLinks(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        linkId
        libraryKey
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const getPaymentDetails = /* GraphQL */ `
  query GetPaymentDetails(
    $policyNumber: String!
    $fullName: String!
    $postalCode: String!
    $dateOfBirth: String!
  ) {
    getPaymentDetails(
      policyNumber: $policyNumber
      fullName: $fullName
      postalCode: $postalCode
      dateOfBirth: $dateOfBirth
    )
  }
`;
export const getBillingDetails = /* GraphQL */ `
  query GetBillingDetails($policyNumber: String!, $company: String!) {
    getBillingDetails(policyNumber: $policyNumber, company: $company)
  }
`;
export const getChangeRequests = /* GraphQL */ `
  query GetChangeRequests {
    getChangeRequests {
      id
      username
      uniqPolicy
      payload
      isDeleted
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getChangeRequest = /* GraphQL */ `
  query GetChangeRequest($uniqPolicy: Int!) {
    getChangeRequest(uniqPolicy: $uniqPolicy) {
      id
      username
      uniqPolicy
      payload
      isDeleted
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getChangeRequestById = /* GraphQL */ `
  query GetChangeRequestById($id: String!) {
    getChangeRequestById(id: $id) {
      id
      username
      uniqPolicy
      payload
      isDeleted
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getValidEffectiveDates = /* GraphQL */ `
  query GetValidEffectiveDates($startDate: String!, $endDate: String!) {
    getValidEffectiveDates(startDate: $startDate, endDate: $endDate)
  }
`;
export const getVehicleYears = /* GraphQL */ `
  query GetVehicleYears {
    getVehicleYears
  }
`;
export const getVehicleMakes = /* GraphQL */ `
  query GetVehicleMakes($year: Int!) {
    getVehicleMakes(year: $year)
  }
`;
export const getVehicleModels = /* GraphQL */ `
  query GetVehicleModels($year: Int!, $make: String!) {
    getVehicleModels(year: $year, make: $make)
  }
`;
export const getProofOfInsurance = /* GraphQL */ `
  query GetProofOfInsurance(
    $lookupCode: String!
    $policyId: String!
    $policyCode: String!
  ) {
    getProofOfInsurance(
      lookupCode: $lookupCode
      policyId: $policyId
      policyCode: $policyCode
    )
  }
`;
export const getPolicies = /* GraphQL */ `
  query GetPolicies {
    getPolicies
  }
`;
export const getAvailableTimeSlots = /* GraphQL */ `
  query GetAvailableTimeSlots {
    getAvailableTimeSlots
  }
`;
export const getScheduledCall = /* GraphQL */ `
  query GetScheduledCall {
    getScheduledCall
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($lookupCode: String) {
    getProfile(lookupCode: $lookupCode)
  }
`;
export const getCoverages = /* GraphQL */ `
  query GetCoverages(
    $lookupCode: String!
    $policyId: String!
    $policyType: String!
  ) {
    getCoverages(
      lookupCode: $lookupCode
      policyId: $policyId
      policyType: $policyType
    )
  }
`;
export const getBusinessDays = /* GraphQL */ `
  query GetBusinessDays($startDate: String!, $endDate: String!) {
    getBusinessDays(startDate: $startDate, endDate: $endDate)
  }
`;
export const getCompanyCodes = /* GraphQL */ `
  query GetCompanyCodes {
    getCompanyCodes {
      code
      name
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($uniqActivity: Int!) {
    getActivity(uniqActivity: $uniqActivity)
  }
`;
export const getActivities = /* GraphQL */ `
  query GetActivities($uniqPolicy: Int!, $lookupCode: String!) {
    getActivities(uniqPolicy: $uniqPolicy, lookupCode: $lookupCode) {
      activityId
      activityCode
      uniqEntity
      lookupCode
      uniqPolicy
      workgroup
      closedStatus
      insertedDate
      updatedDate
    }
  }
`;
export const getActivityNotes = /* GraphQL */ `
  query GetActivityNotes($uniqActivity: Int!) {
    getActivityNotes(uniqActivity: $uniqActivity) {
      uniqActivity
      uniqActivityCode
      note
    }
  }
`;
export const getMyProofOfInsuranceStatus = /* GraphQL */ `
  query GetMyProofOfInsuranceStatus {
    getMyProofOfInsuranceStatus
  }
`;
export const getApplicant = /* GraphQL */ `
  query GetApplicant(
    $lookupCode: String!
    $policyType: String!
    $uniqPolicy: Int!
  ) {
    getApplicant(
      lookupCode: $lookupCode
      policyType: $policyType
      uniqPolicy: $uniqPolicy
    ) {
      fullName
      birthDate
      insertedDate
    }
  }
`;
export const getLobs = /* GraphQL */ `
  query GetLobs($lookupCode: String!) {
    getLobs(lookupCode: $lookupCode)
  }
`;
export const getRatesbotSettings = /* GraphQL */ `
  query GetRatesbotSettings {
    getRatesbotSettings
  }
`;
export const getRatesbotObject = /* GraphQL */ `
  query GetRatesbotObject($uniqPolicy: Int!) {
    getRatesbotObject(uniqPolicy: $uniqPolicy) {
      uniqPolicy
      uniqEntity
      payload
      isOffer
    }
  }
`;
export const getRatesbotOffers = /* GraphQL */ `
  query GetRatesbotOffers($lookupCode: String!) {
    getRatesbotOffers(lookupCode: $lookupCode) {
      uniqPolicy
      policyNumber
      insertedDate
      activityCode
      note
    }
  }
`;
export const getRatesbotObjectByBroker = /* GraphQL */ `
  query GetRatesbotObjectByBroker($uniqPolicy: Int!) {
    getRatesbotObjectByBroker(uniqPolicy: $uniqPolicy) {
      statusCode
      uniqPolicy
      uniqEntity
      companyCode
      policyNumber
      lookupCode
      payload
      isOffer
      scenario
      state
      errorMessage
    }
  }
`;
export const setRatesbotObjectByBroker = /* GraphQL */ `
  query SetRatesbotObjectByBroker(
    $uniqPolicy: Int!
    $uniqOriginalPolicy: Int
    $uniqEntity: Int
    $policyNumber: String
    $lookupCode: String
    $companyCode: String!
    $initial: Boolean
  ) {
    setRatesbotObjectByBroker(
      uniqPolicy: $uniqPolicy
      uniqOriginalPolicy: $uniqOriginalPolicy
      uniqEntity: $uniqEntity
      policyNumber: $policyNumber
      lookupCode: $lookupCode
      companyCode: $companyCode
      initial: $initial
    ) {
      statusCode
      uniqPolicy
      uniqEntity
      companyCode
      policyNumber
      lookupCode
      payload
      isOffer
      scenario
      state
      errorMessage
    }
  }
`;
export const getDocuments = /* GraphQL */ `
  query GetDocuments {
    getDocuments
  }
`;
export const getAdminConfig = /* GraphQL */ `
  query GetAdminConfig($config: String!) {
    getAdminConfig(config: $config)
  }
`;
export const getTeamLeadConfig = /* GraphQL */ `
  query GetTeamLeadConfig($config: String!) {
    getTeamLeadConfig(config: $config)
  }
`;
export const getBrokerConfig = /* GraphQL */ `
  query GetBrokerConfig($config: String!) {
    getBrokerConfig(config: $config)
  }
`;
export const getUserConfig = /* GraphQL */ `
  query GetUserConfig($config: String!) {
    getUserConfig(config: $config)
  }
`;
